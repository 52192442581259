@import '../../styles/customMediaQueries.css';

/* Content is visible as modal layer */
.isOpen {
  width: 100%;
  z-index: 100;
  background-color: rgba(0, 0, 0, 0.4);
  @media (--viewportLarge) {
    background-color: transparent;
  }

  /* scrollLayer is the .root for the Modal */
  & .scrollLayer {
    flex-grow: 1;
    display: flex;

    /* Add default background color to avoid bouncing scroll showing the
   page contents from behind the modal. */
    background-color: rgba(0, 0, 0, 0.4);

    /* Additional styles for the modal window, dimming the background and positioning the modal */
    min-height: 100vh;
    overflow: auto;
    -webkit-overflow-scrolling: touch;

    @media (--viewportLarge) {
      justify-content: center;
      align-items: flex-start;
      padding: 0;
      background-color: rgba(0, 0, 0, 0.4);
      background-image: none;
    }
  }

  & .container {
    flex-grow: 1;

    /* Create context so the close button can be positioned within it */
    position: relative;

    /* Display and dimensions */
    display: flex;
    flex-direction: column;
    padding: var(--modalPadding);

    background: var(--colorWhite) !important;
    background-color: var(--colorText);
    border-radius: var(--borderRadiusXLarge);

    min-height: 100vh;
    height: 100%;

    @media (--viewportMedium) {
      flex-basis: 576px !important;
      flex-grow: 0;
      min-height: auto;
      height: auto;

      padding: var(--modalPaddingMedium);
      margin: 12.5vh auto;
    }
  }
}

.isOpenInPlace {
  composes: isOpen;
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.isOpenInPlaceSearchPage {
  composes: isOpen;
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  @media (max-width: 768px) {
    top: 60px;
  }
}

.isOpenInPortal {
  composes: isOpen;
  display: block;
  height: calc(var(--vh, 1vh) * 100);
  position: absolute;

  @media (--viewportMedium) {
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}

.content {
}

/* Content is explicitly hidden (this default can be overridden with passed-in class) */
/* The use case for having both .isOpen and .isClosed is ModalInMobile use case       */
/* where desktop layout should not get any styling from Modal component.              */
.isClosed {
  display: none;
}

.close {
  composes: marketplaceModalCloseStyles from global;
}

.closeText {
  composes: marketplaceModalCloseText from global;
}
.closeIcon {
  composes: marketplaceModalCloseIcon from global;
}

.closeLight {
  color: var(--colorGrey300);
  transition: var(--transitionStyleButton);

  &:enabled:hover,
  &:enabled:active {
    color: var(--colorText);
  }
}

.focusedDiv {
  &:focus {
    outline: none;
  }
}

.gallaryModal {
  &.isOpenInPortal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    & .scrollLayer {
      & .container {
        margin: 0;
        max-width: 100%;
      }
    }
  }
  & .scrollLayer {
    & .container {
      flex-basis: 100%;
      width: 100%;
      max-width: 800px !important;
      padding: 16px;
      margin-top: 12.5vh !important;
      @media (--viewportMedium) {
        padding: 24px;
      }
      @media (max-width: 767px) {
        display: flex;
        flex-direction: column;
        justify-content: start;
        margin-top: 0 !important;
        & .content {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          flex-grow: 1;
          & :global(.slick-slider) {
            width: 100%;
          }
        }
      }
      & .modalHeader {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 20px;
        position: relative;
        padding: 0;
        padding-bottom: 10px;
        & .close {
          position: relative;
          display: flex;
          justify-content: flex-end;
          padding: 0;
          width: auto;
          order: 2;
        }
        & > h2 {
          order: 1;
          font-size: 20px;
          color: var(--matterColorDark);
          margin: 0;
          line-height: 120%;
          flex-grow: 1;
          @media (--viewportMedium) {
            font-size: 24px;
          }
        }
      }
      & .content {
        display: flex;
        align-items: stretch;
        position: relative;
        & :global(.slick-slider) {
          position: relative;
        }
        & :global(.slick-dots) {
          position: absolute;
          bottom: 10px;
        }
        & :global(.slick-dots > li) {
          margin: 0;
        }
        & :global(.slick-dots > li > button) {
          width: 8px;
          height: 8px;
          cursor: pointer;
          border-radius: 50%;
          background-color: var(--colorText);
          border: none;
          margin: 0 6px 0 0;
        }

        & :global(.slick-dots > li.slick-active > button) {
          background-color: var(--marketplaceColor);
        }
        & :global(.slick-dots > li > button::before) {
          display: none;
        }

        & :global(.slick-arrow) {
          position: absolute;
          z-index: 1;
          top: 50%;
          transform: translateY(-50%);
          width: 40px;
          height: 40px;
          display: inline-flex;
          border-radius: 100px;
          align-items: center;
          justify-content: center;
          border: solid 1px var(--colorText);
          transition: var(--transitionStyle);
          border: solid 1px var(--colorGrey500);
          background-color: var(--colorGrey50);
          &:hover {
            background-color: var(--colorGrey50);
            border: solid 1px var(--colorGrey500);
            transition: var(--transitionStyle);
          }
        }
        & :global(.slick-arrow > svg) {
          width: 30px;
          height: 30px;
        }
        & :global(.slick-prev > svg) {
          transform: rotate(0deg);
        }
        & :global(.slick-prev::before),
        & :global(.slick-next::before) {
          display: none;
        }
        & :global(.slick-prev) {
          left: 30px;
        }

        & :global(.slick-next) {
          right: 30px;
        }
        & :global(.AspectRatioWrapper_aspectPadding) {
          padding-bottom: 100% !important;
        }
      }
    }
  }
}
.modalHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 100;
  background-color: var(--colorText);
  width: 100%;
  padding: 16px 24px;
  border-bottom: solid 1px var(--colorGrey100);

  & > h2 {
    font-size: 26px;
    color: var(--colorGrey800);
  }
  & .close {
    position: relative;
    padding: 0;
  }
}

.bookingModal {
  & .container {
    flex-basis: 100%;
    margin: 0;
  }
}

.imageGalleryContainer {
  background-color: #ffffff !important;
  flex-basis: 90% !important;
  margin: 0 auto;
}
