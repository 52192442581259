@import '../../styles/customMediaQueries.css';

/* Order form inside modalContainer needs special handling */
/* since "inMobile" breakpoint is actually --viewportLarge */
.modalContainer {
  composes: marketplaceModalInMobileBaseStyles from global;
  height: 100vh;

  @media (--viewportMedium) {
    max-width: 576px;
    width: 100%;
    height: unset;
    padding: var(--modalPaddingMedium);
    background-color: var(--colorWhite);
    margin-top: 12.5vh;
    margin-bottom: 12.5vh;
  }

  @media (--viewportLarge) {
    padding: 0;
    background-color: transparent;
    margin-top: 0;
    margin-bottom: 0;
  }
  & > button {
    position: relative;
    display: flex;
    justify-content: flex-end;
    @media (--viewportMedium) {
      position: absolute;
    }
  }
  & > div {
    display: flex;
    flex-direction: column;
    & .priceContainer {
      order: 2;
    }
    & .pricingWrapper {
      order: 1;
    }
    & .bookingForm {
      order: 3;
    }
  }
}

.orderPanelTitle {
  /* Font */
  font-weight: var(--fontWeightBold);

  /* Layout */
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;
}

.modalHeading {
  margin-top: 24px;
  margin-bottom: 0px;
  padding: 0 24px;

  @media (--viewportMedium) {
    padding: 0;
  }

  @media (--viewportLarge) {
    display: none;
  }
}

.heading {
  /* Font */
  color: var(--colorGrey700);

  /* Layout */
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;
  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 5px;
  }
}

.price {
  composes: p from global;
  font-weight: var(--fontWeightRegular);

  color: var(--marketplaceColor);
  margin: 0 0 0 0px;

  @media (--viewportMedium) {
    margin: 0;
    padding: 0;
  }
}

.perUnit {
  /* Font */
  composes: p from global;
  font-weight: var(--fontWeightRegular);

  color: var(--marketplaceColor);
  padding: 0;

  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }

  @media (--viewportLarge) {
  }
}

.author {
  composes: p from global;
  margin: 0px 24px;

  display: flex;
  flex-direction: row;
  align-items: center;

  @media (--viewportMedium) {
    width: 100%;
    margin: 12px 0 36px 0;
    color: var(--colorGrey700);
  }
}

.providerAvatar {
  display: none;

  @media (--viewportLarge) {
    display: flex;
    margin-right: 10px;
  }
}

.providerNamePlain {
  display: inline;

  @media (--viewportLarge) {
    display: none;
  }
}

.providerNameLinked {
  display: none;

  @media (--viewportLarge) {
    display: inline;
  }
}

.orderHeading {
  display: none;

  @media (--viewportLarge) {
    display: block;
    margin-bottom: 0;
  }
}

.orderTitle {
  /* Font */
  color: var(--colorGrey700);

  margin-top: 0;
  margin-bottom: 2px;

  @media (--viewportLarge) {
    margin-bottom: 0;
  }
}

.orderHelp {
  composes: h5 from global;
  display: none;

  @media (--viewportMedium) {
    color: var(--colorGrey700);
    display: block;
    margin-top: 0;
  }
}

.bookingForm {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 0;

  @media (--viewportMedium) {
    padding: 0;
    min-height: 400px;
  }

  @media (--viewportLarge) {
    min-width: 312px;
    min-height: auto;
  }
}

.openOrderForm {
  /* Ensure that mobile button is over Footer too */
  z-index: 9;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 14px 24px;
  background-color: var(--colorWhite);
  box-shadow: var(--boxShadowTop);
  display: flex;

  align-items: center;
  justify-content: space-between;
  /* Contain repainting to this component only */
  /* 3D painting container helps scrolling */
  transform: translate3d(0, 0, 0);

  @media (--viewportLarge) {
    display: none;
  }
  & > button {
    width: auto;
    min-width: 100px;
    min-height: 45px;
  }
}

.priceContainerInCTA,
.priceContainer {
  /* Layout */
  display: flex;
  flex-direction: row;
  align-items: baseline;
  gap: 4px;
  flex-shrink: 0;

  @media (--viewportLarge) {
    flex-direction: row;
    margin-right: unset;
  }
}
.priceContainerInCTA {
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: unset;
}

.pickup {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px 0 20px 0;
  & > span {
    background-color: var(--marketplaceColor);
    color: var(--colorWhite);
    padding: 10px 20px;
    border-radius: var(--borderRadiusMedium);
    & > svg {
      & path {
        stroke: var(--colorWhite);
      }
    }
    & > svg {
      display: inline-flex;
      align-items: center;
      width: 16px;
      height: 16px;
      margin-top: -4px;
      margin-right: 4px;
    }
  }
}
.priceContainer {
  font-size: 16px;
  font-weight: var(--fontWeightBold);
  color: var(--colorGrey900);
  justify-content: center;
  border-bottom: solid 1px var(--colorGrey100);
  padding: 10px 0;
  margin-bottom: 10px;
}

.priceValue {
  composes: h3 from global;
  color: var(--marketplaceColor);
  line-height: 120%;
  font-size: 18px;

  margin-top: 0;
  margin-bottom: 0px;
  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.perUnitInCTA {
  /* Font */
  composes: marketplaceTinyFontStyles from global;
  color: var(--marketplaceColor);
  font-weight: var(--fontWeightSemiBold);
  padding: 0;
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }

  @media (--viewportLarge) {
  }
}

.closedListingButton {
  border-left: 1px solid var(--colorGrey100);
  width: 100%;
  padding: 15px 24px 15px 24px;
  text-align: center;
}

.receipt {
  flex-shrink: 0;
}

.error {
  composes: h4 from global;
  color: var(--colorFail);

  padding: 0 24px;

  @media (--viewportMedium) {
    padding: 0;
  }
}
.errorSidebar {
  color: var(--colorFail);
  padding: 0 24px;

  @media (--viewportMedium) {
    padding: 0;
  }
}

.perDayPrice {
  color: green; /* Change to your desired color */
}

.perWeekPrice {
  color: green; /* Change to your desired color */
}

.perMonthPrice {
  color: green; /* Change to your desired color */
}
.noPriceSelected {
  color: black;
}

.pricingWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;

  & .rateSec {
    font-size: 14px;
    font-weight: var(--fontWeightSemiBold);
    line-height: 120%;
    padding: 10px 16px;
    width: calc(100% / 3);
    background-color: #f2f2f2;
    border-radius: 100px;
    @media (--viewportMedium) {
      padding: 16px;
    }
    &:not(:last-child) {
      border-right: solid 2px var(--colorWhite);
    }

    & > span {
      display: flex;
      text-align: center;
      flex-direction: column;
      width: 90%;
      margin: 0 auto;
      text-transform: uppercase;

      @media (--viewportMedium) {
        width: 100%;
      }
    }
  }
}
